
#project {

    body {
        overflow-x: hidden; 
    }

    // Flickity carousel. Name: "asNavFor" From: https://flickity.metafizzy.co/options.html 
    .carousel {
        margin-bottom: 33px;

        @include mobile {
            position: relative; 
            top: 100px;
            margin-bottom: 150px;
        }
        @include small-mobile {
            position: relative;
            top: 100px;
            margin-bottom: 150px; 
        }
    }
      .carousel-main {
        background: $grey8;
        .carousel-cell {
            height: 550px;
            width: 100%; 
            counter-increment: carousel-cell;
            @include mobile {
                width: 100%;
                height: 350px;
                margin-right: 10px;
                margin-top: 6px;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -ms-flex-align: center;
                -webkit-align-items: center;
                -webkit-box-align: center;
                align-items: center;
            }
            @include small-mobile {
                width: 100%;
                height: 350px;
                margin-right: 10px;
                margin-top: 6px;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -ms-flex-align: center;
                -webkit-align-items: center;
                -webkit-box-align: center;
                align-items: center;
            }
            img {
                max-height: 100%;
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }
          }
      }
      .flickity-page-dots {
          display: none; 
      }
    .carousel-nav {
        @include mobile {
            display: none
        }
        @include small-mobile {
            display: none;
        }
        .flickity-button {
            display: none; 
        }
      }
      .flickity-prev-next-button {
          top: 90%; 
          @include mobile {
            display: none
        }
        @include small-mobile {
            display: none;
        }
      }
      .flickity-button-icon {
        fill: $grey9;
      }
      .carousel-cell {
        width: 100%;
        height: 200px;
        margin-right: 10px;
        counter-increment: carousel-cell;
      }
      /* cell number */
      .carousel-cell:before {
        display: none;
        text-align: center;
        content: counter(carousel-cell);
        line-height: 200px;
        font-size: 80px;
        color: white;
      }
      .carousel-nav .carousel-cell {
        height: 80px;
        width: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .carousel-nav .carousel-cell:before {
        font-size: 50px;
        line-height: 80px;
      }
      .carousel-nav .carousel-cell.is-nav-selected {
        opacity: 0.2;
      }


    #pd-description {
        margin: 0px 20px 25px 20px;
        @include mobile {
            margin: 40px 40px 45px 40px;
        }
        @include small-mobile {
            margin: 40px 40px 45px 40px;
        }
        #credits {
            a {
                p {
                    transition: all .4s ease-in-out;
                    -moz-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                }
                p:hover {
                    color: $grey5 !important;
                }
            }
        }
        p {
            margin-bottom: 40px;
        }
        .h11 {
            margin-bottom: -22px; 
        } 
    }
    
}

