body {

    @include tablet {
        p {
            font-size: 19px;
        }
    }
    @include mobile {
        p {
            font-size: 19px;
        }
    }
    @include small-mobile {
        p {
            font-size: 19px; 
        }
    }
}


/* BANNER SECTION */
#about-banner {
    text-align: center;
 
    #img-about-banner {
        width: 100%;
        height: 60vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: inline-block;

        @include tablet {
            margin: 110px 0px 0px 0px;
            height: 30vh;
        }
        @include mobile {
            margin: 110px 0px 0px 0px;
            height: 23vh;
        }
        @include small-mobile {
            margin: 110px 0px 0px 0px;
            height: 17vh;
        }
    }
}


/* COMPANY OVERVIEW */
#company-overview {
    position: relative;
    padding-top: 27px;

    h2 {
        text-align: center;

        @include mobile {
            text-align: left;
        }
        @include small-mobile {
            text-align: left;
            margin-bottom: 50px; 
        }
    }
    .row {
        margin: 0px 40px 0px 40px;
    }
    p {
        margin: 0px 0px 30px 0px;
        text-align: center;

        @include mobile {
            margin: 0px 0px 30px 0px;
            text-align: left;
        }
        @include small-mobile {
            margin: 0px 0px 30px 0px;
            text-align: left;
        }
    }

    @include tablet {
        padding-top: 0px;
    }
    @include mobile {
        padding-top: 0px;
    }
    @include small-mobile {
        padding-top: 0px;
    }


    #btn-section {
        width: 100%;
        margin-left: 275px;
        margin-bottom: -35px;

        @include large-desktop {
            margin-left: 275px;
        }
        @include desktop {
            margin-left: 270px;
        }
        @include small-desktop {
            margin-left: 180px;
        }
        @include tablet {
            margin-left: 200px;
        }
        @include mobile {
            display: none;
        }
        @include small-mobile {
            display: none;
        }

        .col-sm-12.col-md-3.col-lg-3 {
            padding-left: 0px;

            @include desktop {

            }
            @include small-desktop {
                margin-right: 50px;
            }
        }
    }
    .btn-drk {
        margin-bottom: 25px !important;
    }
    h5 {
        margin: 0.1px;
    }
}


/* HEADLINE */
.headline-about {
    width: 100%;
    height: 532px;
    margin-top: 82px;


    #hl-about {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0px 56px 0px 56px;
        height: 532px;
        background-image: url("../../src/img/highlight.jpg");

        h1 {

            @include tablet {
                font-size: 36px;
            }
            @include mobile {
                font-size: 36px;
            }
            @include small-mobile {
                font-size: 36px;
            }
        }
    }
}



/* BIO SECTION */
#bio-section {
    position: relative;
    margin: 65px 0px 65px 0px;

    @include tablet {
        margin: 65px 20px 65px 20px;
    }
    @include mobile {
        margin: 65px 20px 65px 20px;
    }
    @include small-mobile {
        margin: 65px 20px 65px 20px;
    }

    h3 {
        margin: 0 0 15px 0;
    }
    .name-1 {
        @media (min-width: 1200px) {
            display: none;
        }
    }
    .name-2 {
        @media (max-width: 1200px) {
            display: none;
        }
    }

    img {
        width: auto;
        max-width: 100%;
        max-height: 500px;
        margin-bottom: 35px;
    }
    #eh-img {
        margin: 43px 0px 35px 0px;

        @media (max-width: 1200px) {
            margin: 0px 0px 35px 0px;
        }
    }
    #eh-bio {
        margin: 0px 0px 0px 0px;
        padding-bottom: 85px;

        p {
            margin: 0px 0px 0px 0px;
        }
    }
    #div-line {
        width: 100%;
        height: 2px;
        border-bottom: 2px solid $grey2;
    }
    #jc-img {
        margin: 95px 0px 0px 0px;

        @media (max-width: 1200px) {
            margin: 0px 0px 0px 0px;
        }
    }
    #jc-bio {
        margin: 85px 0px 0px 0px;

        @media (max-width: 1200px) {
            margin: 35px 0px 0px 0px;
        }

        p {
            margin: 0px 0px 0px 0px;
        }
    }
}
