#navbar {
    width: 100%;
    height: 75px;
    position: relative;
    margin: 0px;
    padding: 0px;
    background-color: #555759;

    @include tablet {
        display: none;
    }
    @include mobile {
        display: none;
    }
    @include small-mobile {
        display: none;
    }

    .nav-visited {
        color: $grey7;
        font-style: italic;
    }
    a:hover {
        color: $grey7;
    }
    a {
      transition: all .4s ease-in-out;
      -moz-transition: all .4s ease-in-out;
      -webkit-transition: all .4s ease-in-out;
    }

    .row {
        margin-left: -55px;

        @media (max-width: 1260px) {
            margin-left: -15px;
        }
    }

    ul {
        position: relative;
        list-style-type: none;
        margin: -14px 0px 25px -120px;
        height: 75px;
        z-index: 998;

        @media (max-width: 1360px) {
            margin: -14px 0px 25px -65px;
        }

        li {
            float: left;
            height: 75px;
            padding: 0px 37px 35px 37px;
            text-align: center;
        }
    }
}


    #logo-and-toggle {
        float: right;
        background-color: $white;
        position: absolute;
        height: 119px;
        width: 375px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        padding: 33px 0px 0px 0px;
        z-index: 998;

        @include small-desktop {
            width: 360px;
            height: 114.4px;
            padding: 30px 0px 0px 0px;
        }
        @include tablet {
            width: 100%;
            height: 114.4px;
            padding: 30px 0px 0px 0px;
        }
        @include mobile {
            width: 100%;
            height: 114.4px;
            padding: 30px 0px 0px 0px;
        }
        @include small-mobile {
            width: 100%;
            height: 114.4px;
            padding: 30px 0px 0px 0px;
        }


        #logo-eh {
            display: block;
            margin: 0 auto;
            width: 232px;
            height: 49.8px;

          @include small-desktop {
              width: 222.6px;
              height: 47.7px;
          }
          @include tablet {
              width: 222.6px;
              height: 47.7px;
          }
          @include mobile {
              width: 222.6px;
              height: 47.7px;
          }
          @include small-mobile {
              width: 222.6px;
              height: 47.7px;
              margin: 0px 0px 0px 45px;
          }
       }

       #mobile-toggle {
           display: none;
           float: right;
           height: 33.29px;
           width: 33.29px;
           z-index: 999;
           cursor: pointer;

           @include tablet {
               display: inline;
               margin: -40px 25px 0px 0px;
           }
           @include mobile  {
               display: inline;
               margin: -40px 25px 0px 0px;
           }
           @include small-mobile {
               display: inline;
               margin: -40px 40px 0px 0px;
           }
       }
    }



    #mobilenav {
        display: none;
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 999;
        left: 0;
        top: 0;
        background-color: $grey1;
        overflow-y: scroll;

        ul {
            margin: 65px;
            text-align: center;
            list-style-type: none;
            padding: 0px;

            li {
                margin: 0px 0px 55px 0px;
            }

            #mobile-toggle-2 {
                margin-top: 30px;
                height: 45px;
                width: 45px;
                cursor: pointer;
                -ms-transform: rotate(45deg); /* IE 9 */
                -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                transform: rotate(45deg);
            }
        }
    }
