body {

    @include tablet {
        p {
            font-size: 22px;
        }
    }
    @include mobile {
        p {
            font-size: 22px;
        }
    }
    @include small-mobile {
        p {
            font-size: 22px; 
        }
    }
}

    /* Mobile Swiper */ 
    .swiper4 {
        width: 100%;
        height: 100%;
        margin-top: 115px;

        @media (min-width: $tablet-width) {
            display: none;
        }
    }

/* Large Screen Swiper */
.swiper3 {
    width: 100%;
    height: 100%;

    @include mobile {
        display: none
    }
    @include small-mobile {
        display: none;
    }
}

.swiper-button-prev, .swiper-button-next {
    top: 90% !important;
    margin: 0px 40px 0px 40px;
} 

.swiper-slide {
    text-align: center;
    font-size: 15px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

/* WELCOME SPLASH PAGE */
#welcome {
    display: none;
    z-index: 999;
    background-color: #FFF;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    #welcome-logo {
        width: 290px;
    }
}


/* BANNER SECTION */
#banner {
    position: relative;
    .carousel-cell {
        width: 66%; 
        height: 200px;
        margin-right: 10px;
        counter-increment: gallery-cell;
      }
      
      /* cell number */
      .carousel-cell:before {
        display: none;
        text-align: center;
        content: counter(gallery-cell);
        line-height: 200px;
        font-size: 80px;
        color: white;
      }
      .flickity-page-dots {
          display: none; 
      }
      .flickity-button {
          display: none; 
      }

    #banner-img {
        width: 100%;
        height: 80vh; 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    #indicator-circle {
        margin-top: 70vh;
        margin-left: 5vh;
        height: 40px;
        width: 40px;
        background-color: $white;
        box-shadow: $box-shadow-circular;
        border-radius: 20px;
        z-index: 990;
        position: absolute;
        bottom: 30px;
        left: 30px;

        &:hover {
          transition: all .15s ease;
          transform: translateY(3px);
        }

        #down-arrow {
            width: 23px;
            margin: 14px 3px 14px 8px;
            z-index: 999;
        }
    }
}


/* SECTION 1: GENERAL DESCRIPTION */
#section-1 {
    position: relative;
    padding-top: 25px;
    .intro {
        padding: 0 80px;
        
        @include tablet {
            padding: 0 50px;
        }
        @include mobile {
            padding: 0;
        }
        @include small-mobile {
            padding: 0;
        }
        p {
            margin-bottom: 45px;
        }
        .btn-light {
            display: inline-block;
        }
        .btn-drk {
            display: inline-block;
            margin-right: 20px !important;
            margin-bottom: 25px !important;
        }
        h5 {
            margin: 0.1px;
        }
    }
}


/* HEADLINE SECTION */
.headline-bg {
    width: 100%;
    height: 532px;
    margin-top: 82px;

    #hl-bg {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0px 56px 0px 56px;
        height: 532px;
    }
    h1 {
        @include mobile {
            font-size: 42px;
        }
        @include small-mobile {
            font-size: 42px;
        }
    }
}


/* SECTION 2: RECENT PROJECTS */
#section-2 {
    position: relative;
    padding: 20px 0 40px 0;
    
    h2 {
        margin-bottom: 55px;
    }

    .project {
        margin-bottom: 40px;
        
        @include tablet {
            margin-bottom: 0;
        }
        @include mobile {
            margin-bottom: 0;
        }
        @include small-mobile {
            margin-bottom: 0;
        }
    }
    
    #proj-descr {

        p {
            margin: -10px 0px -35px 0px;
        }
        h6 {
            transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
        }
        h6:hover {
            color: $grey5 !important;
        }
    }
}
