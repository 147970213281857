/* Banner Image */
#banner {
    //background-image: url("../../src/img/placeholder-imgs/MYD_1344.jpg");
    width: 100%;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} 


/* COLUMN 1: CONTACT INTRO */
#contact-intro {
    margin-top: 90px;

    @include tablet {
        margin-top: 0px;
        padding: 40px;
        p {
            font-size: 19px;
        }
    }
    @include mobile {
        margin-top: 0px;
        padding: 40px;
        p {
            font-size: 19px;
        }
    }
    @include small-mobile {
        margin-top: 0px;
        padding: 40px;
        p {
            font-size: 19px;
        }
    }

    #description {
        line-height: 1.35em;
        margin-right: 90px;

        @include tablet {
            margin-right: 0px;
        }
        @include mobile {
            margin-right: 0px;
        }
        @include small-mobile {
            margin-right: 0px;
        }
    }
    #address-ln-1 {
        line-height: 0.25em;
        margin-top: 21px;
    }
    #address-ln-2 {
        line-height: 0.25em;
        margin-bottom: 50px;
    }
    #phone-fax {
        line-height: 1.35em;
        margin-top: 7px;
    }
    #email {
        margin-top: 5px;
    }
}
#contact-intro h2 {
    margin-top: 10px;
    margin-bottom: -10px;
}
.contact-info p {
    margin-bottom: 8px;
}


/* COLUMN 2: FORM */
#contact_form {
    margin: 115px 0px 50px 0px;
    position: relative;
    overflow: hidden;

    @include small-desktop {
        clear: both;
    }
    @include tablet {
        clear: both;
        margin: 20px 40px 40px 40px;
    }
    @include mobile {
        clear: both;
        margin: 20px 40px 40px 40px;
    }
    @include small-mobile {
        clear: both;
        margin: 20px 40px 40px 40px;
    }
    .input-field, .tel-number-field {
        height: 70px;
        margin-bottom: 25px;
    }
    .h13 {
        margin-bottom: -5px; 
    }
    #message {
        height: 214px;
        margin-bottom: 25px;
    }
    #submit_btn {
        border-radius: 0px; 
        width: 227px;
        height: 64px;
        background-color: $grey1;
        font-family: $primary-font;
        border: 3px solid $grey1;
        font-size: 27px;
        font-weight: $semibold;
        font-style: italic;
        color: $white;
        transition: all .4s ease-in-out;
        -moz-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
    }
    #submit_btn:hover {
        background-color: $white !important;
        color: $grey1;
    }
    #thanks {
        display: none;
        
        h2 {
            font-size: 22px;
            color: #999;
            margin: 0 0 10px 0;
        }
    }
    #error {
        display: none;
        
        h2 {
            font-size: 22px;
            color: #999;
            margin: 0 0 10px 0;
        }
    }
}
