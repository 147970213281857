/*----GENERAL----*/
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
img {
    border: 0;
    max-width: 100%;
}
::-moz-selection {
    color: $white; 
}
::selection {

}

*:focus {
    outline: none;
}

/*----BTNS----*/
button:focus {
    outline:0;
}
.btn-drk {
    line-height: 5.9em !important;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 1px 0px !important;
    height: 64px !important;
    width: 227px !important;
    background-color: $grey1 !important;
    text-align: center !important;
    border: 3px solid $grey1;
    transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out; 
}
.btn-drk:hover {
    background-color: $white !important;

    h5 {
        color: $grey1;
    }
}
.btn-light {
    line-height: 5.9em !important;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 1px 0px !important;
    height: 64px !important;
    width: 227px !important;
    background-color: $grey2 !important;
    text-align: center !important;
    border: 3px solid $grey2;
    transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
}
.btn-light:hover {
    background-color: $white !important;

    h5 {
        color: $grey2;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $greyE inset !important;
}

/*----LINKS----*/
a {
    text-decoration: none !important;
    color: inherit;
}
a:hover {
    text-decoration: none;
}
 