.footer {
    background-color: $grey1;
    width: 100%;
    padding: 20px 0px 45px 0px;
    clear: both;

    // @include tablet {
    //     padding: 40px 0px 35px 0px;
    // }
    // @include mobile {
    //     padding: 40px 0px 35px 0px;
    // }
    // @include small-mobile {
    //     padding: 40px 0px 35px 0px;
    // }

    #ft-link {
        cursor: pointer;

        img /* Logo */ {
            margin: 70px 0px 0px 0px;
            width: 305px;
            text-align: left;

            @include desktop {
                margin: 80px 0px 0px 0px;
            }
            @include small-desktop {
                margin: 80px 0px 0px 0px;
            }
            @include tablet {
                display: block;
                margin: auto;
                padding: 20px 0px 0px 0px;
            }
            @include mobile {
                display: block;
                margin: auto;
                padding: 20px 0px 0px 0px;
            }
            @include small-mobile {
                display: block;
                margin: auto;
                padding: 20px 0px 0px 0px; 
            }
        }
    }

    #footer-text {
        text-align: right;
        margin: 45px 0px 0px 0px;
        line-height: 2.8em;

        .mailto {
            margin-bottom: 13px; 
        }

        .line-break {
            display: none;

            @include tablet {
                display: inline;
            }
            @include mobile {
                display: inline;
            }
            @include small-mobile {
                display: inline;
            }
        }

        .desktop {
            display: inline;

            @include tablet {
                display: none;
            }
            @include mobile {
                display: none;
            }
            @include small-mobile {
                display: none;
            }
        }

        .mobile {
            display: none;

            @include tablet {
                display: inline;
            }
            @include mobile {
                display: inline;
            }
            @include small-mobile {
                display: inline;
            }
        }
        .desktop, .mobile {
            a:hover {
                color: #FFFFFF;
            }
            a {
              transition: all .4s ease-in-out;
              -moz-transition: all .4s ease-in-out;
              -webkit-transition: all .4s ease-in-out;
            }
        }

        .col-sm-12.col-md-6.col-lg-6 {

            @include tablet {
                margin: 55px 0px 0px 0px;
            }
            @include mobile {
                margin: 55px 0px 0px 0px;
            }
            @include small-mobile {
                margin: 55px 0px 0px 0px;
            }
        }

        @include tablet {
            text-align: left;
            width: 330px;
            display: block;
            margin: 0 auto;
        }
        @include mobile {
            text-align: left;
            width: 330px;
            display: block;
            margin: 0 auto;
        }
        @include small-mobile {
            text-align: left;
            width: 330px;
            display: block;
            margin: 0 auto;
        }
    }
}
