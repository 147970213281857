h1 /* White Headline Text */ {
    font-family: $primary-font; 
    font-size: 52px;
    font-weight: $book;
    font-style: italic;
    color: $white;
}
h2 /* Grey Headline Text */ {
    font-family: $primary-font;
    font-size: 42px;
    font-weight: $book;
    font-style: italic;
    color: $grey1;
}
h3 {
    /* Smaller Grey Headline Text */
    font-family: $primary-font;
    font-size: 30px;
    font-weight: $medium;
    font-style: italic;
    color: $grey1;
}
h4 /* Navbar and Mobilenav Text */ {
    font-family: $primary-font;
    font-size: 25px;
    font-weight: $light;
    color: $white;
}
h5 /* BTN Text */ {
    font-family: $primary-font;
    font-size: 24px;
    font-weight: $semibold;
    font-style: italic;
    color: $white;
}
h6 /* "View More" */ {
    font-family: $primary-font;
    font-size: 22px;
    font-weight: $semibold;
    font-style: italic;
    color: $grey2;
}
p, .textarea-field, .input-field, .tel-number-field  {
    font-family: $secondary-font;
    font-size: 24px;
    font-weight: $light;
    color: $grey1;
}
.h7 /* White Footer Text */ {
    font-family: $secondary-font;
    font-size: 17px;
    font-weight: $light;
    color: $white;
}
.h8 /* Grey Footer Text */ {
    font-family: $secondary-font;
    font-size: 17px;
    font-weight: $light;
    font-style: italic;
    color: $grey2;
}
.h9 /* Small Grey Footer Text */ {
    font-family: $secondary-font;
    font-size: 13px;
    font-weight: $light;
    color: $grey2;
}
.h10 /* Italic Small Grey Footer Text */ {
    font-family: $secondary-font;
    font-size: 13px;
    font-weight: $medium;
    font-style: italic;
    color: $grey2;
}
.h11 /* Inquire Page Small Grey Italic Headings */ {
    font-family: $primary-font;
    font-size: 17px;
    font-weight: $medium;
    font-style: italic;
    color: $grey2;
    text-transform: uppercase;
}
.h12 /* Inquire Page Grey Parentheses */ {
    font-family: $secondary-font;
    font-size: 23px;
    font-weight: $light;
    font-style: italic;
    color: $grey2;
}
.h13 /* Inquire Page: Grey Form Text */ {
    font-family: $secondary-font;
    font-size: 17px;
    font-weight: $light;
    color: $grey2;
}
.h14 /* Projects Page Btn's */ {
    font-family: $secondary-font;
    font-size: 23px;
    font-weight: $light;
    color: $grey4;
}
.h15 /* Proj. Detail Page Title */ {
    font-family: $primary-font;
    font-size: 42px;
    font-weight: $medium;
    font-style: italic;
    color: $grey1;
}
