/*---------------
  LIGHTBOX
---------------*/
.modal {
    .modal-body {
        padding: 20px;
        text-align: center;
    }
    .modal-dialog {
        @include large-desktop {
            width: 800px;
        }
        @include desktop {
            width: 800px;
        }
        @include small-desktop {
            width: 800px;
        }
    }
    .modal-content {
        background-color: transparent;
        border: 0;
    }
    .close-icon {
        color: $red;
        display: inline-block;
        position: relative;
        z-index: 1000;
        float: right;
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;

        &:hover {
            opacity: 0.7;
        }
        span {
            font-size: 30px;
        }
        img {
            display: inline-block;
            position: relative;
            top: -2px;
            width: 23px;
            height: auto;
            margin-right: 10px;
        }
    }
    img{
        margin-bottom: 0;
    }
}
.carousel-inner {
    img {
        border: none;
        box-shadow: $box-shadow;
        max-height: 500px;
    }
}
.carousel-control {
    background-color: transparent;
    position: relative;
    display: inline-block;
    top: auto;
    bottom: 0;
    opacity: 1;
    width: auto;
    margin: 0 20px;
    transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;

    &:hover {
        opacity: 0.8;
    }
    .arrow {
        font-size: 40px;
        color: $blue;
    }
}
.carousel-caption {
    background-color: transparent;
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    padding: 10px;
    text-align: center;
    text-shadow: none;

    p {
        color: $grey7;
        font-weight: 300;
        margin: 15px 0 0 0;
    }
}
