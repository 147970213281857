#proj-banner {
    text-align: center;

    #img-1 {
        // background-image: url("../../src/img/placeholder-imgs/proj-page-banner-02.jpg");
        width: 100%;
        height: 60vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: inline-block;


        @include tablet {
            margin: 110px 0px 0px 0px;
            height: 30vh;
        }
        @include mobile {
            margin: 110px 0px 0px 0px;
            height: 23vh;
        }
        @include small-mobile {
            margin: 110px 0px 0px 0px;
            height: 17vh;
        }
    }
}

#mobile-btn-section {
    height: 60px;
    background-color: $grey6;
    width: 100%;
    text-align: center;
    padding-top: 12px;
    margin-top: -2px;

    @media (max-width: 1600px) {
    }
    @include large-desktop {
        display: none;
    }
    @include desktop {
        display: none;
    }
    @include small-desktop {
        display: none;
    }
    @include tablet {
        display: inline-block;
    }
    @include mobile {
        display: inline-block;
    }
    @include small-mobile {
        display: inline-block;
    }

    #left-arrow {
        height: 25px;
        float: left;
        margin: 6px 0px 0px 25px;
    }
    #right-arrow {
        height: 25px;
        float: right;
        margin: -22px 25px 0px 0px;
    }

    .h14 {
        color: $white;
        margin-right: 35px;
    }
}



#btn-section {
    margin: 65px 0px 65px 35px;
    overflow: hidden;

    @include tablet {
        display: none;
    }
    @include mobile {
        display: none;
    }
    @include small-mobile {
        display: none;
    }
    @media (max-width: 480px) {
        display: none;
    }

    #btn-container {
        width: 705px;

        @include tablet {

        }
        @include mobile {
            margin-left: -5%;
        }
        @include small-mobile {
            margin-left: -33%;
        }
        @media (max-width: 480px) {
            margin-left: -40%;
        }

        .btn-1 {
            width: 231px;
            height: 54px;
            border: 2px solid $grey5;
            text-align: center;
            display: inline-block;
            padding-top: 10px;
            margin: 0px 10px 0px -3px;
            transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
        }
        .btn-1:hover {
            background-color: $grey5;
        }
        .btn-1-visited {
            background-color: $grey6;
            border: 2px solid $grey6 !important;
            width: 231px;
            height: 54px;
            border: 2px solid $grey5;
            text-align: center;
            display: inline-block;
            padding-top: 10px;
            margin: 0px 10px 0px -3px;
            transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;

            .h14 {
                color: $white;
            }
        }
        .btn-2 {
            width: 231px;
            height: 54px;
            border: 2px solid $grey5;
            text-align: center;
            display: inline-block;
            padding-top: 10px;
            margin: 0px 10px 0px -3px;
            transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
        }
        .btn-2:hover {
            background-color: $grey5;
        }
        .btn-2-visited {
            background-color: $grey6;
            border: 2px solid $grey6 !important;
            width: 231px;
            height: 54px;
            border: 2px solid $grey5;
            text-align: center;
            display: inline-block;
            padding-top: 10px;
            margin: 0px 10px 0px -3px;
            transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;

            .h14 {
                color: $white;
            }
        }
        .btn-3 {
            width: 231px;
            height: 54px;
            border: 2px solid $grey5;
            text-align: center;
            display: inline-block;
            padding-top: 10px;
            margin: 0px -1.5px 0px -3px;
            transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
        }
        .btn-3:hover {
            background-color: $grey5;
        }
        .btn-3-visited {
            background-color: $grey6;
            border: 2px solid $grey6 !important;
            width: 231px;
            height: 54px;
            text-align: center;
            display: inline-block;
            padding-top: 10px;
            margin: 0px -1.5px 0px -3px;
            transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;

            .h14 {
                color: $white;
            }
        }
    }
}



#projects-section {

    .row {
        margin-bottom: 25px;

        .clear:nth-child(2n+1){
            clear: both;
        }

        .col-sm-12, .col-md-6, .col-lg-6 {

            @include mobile {
                padding: 0px;
            }
            @include small-mobile {
                padding: 0px;
            }
            @media (max-width: 480px) {
                padding: 0px;
            }
            h3 {
                margin: 15px 0px -20px 0px;

                @include mobile {
                    padding: 0px 15px 0px 15px;
                }
                @include small-mobile {
                    padding: 0px 15px 0px 15px;
                }
                @media (max-width: 480px) {
                    padding: 0px 15px 0px 15px;
                }
            }
            p {
                font-size: 22px;
                margin: 30px 0 40px 0;

                @include mobile {
                    padding: 0px 15px 0px 15px;
                }
                @include small-mobile {
                    padding: 0px 15px 0px 15px;
                }
                @media (max-width: 480px) {
                    padding: 0px 15px 0px 15px;
                }
            }
        }
    }
}
